<template>
  <div class="main">
    <h3>歡迎回來</h3>
    <p>為了提升您的使用體驗，<br/>誠摯邀請您在首次登入時連結Google帳號。</p>
    <div class="other_login">
      <div class="icon"><img class="otherBtn" @click="loginWithGoogle()" :src="require('/src/assets/image/Shared/Login/Single_Google.png')" /></div>
    </div>
    <el-button class="mt-5" data-bs-toggle="collapse" data-bs-target="#showOtherBindEmail">
      <span>其他綁定方式</span>
    </el-button>
    <div class="collapse mt-5" id="showOtherBindEmail">
      <el-form :model="emailForm" :rules="rules" ref="emailForm">
        <div class="email_section">
          <div class="d-flex flex-column mb-3">
            <label class="red_star">* <span>設定Email(請至信箱收取驗證信)</span></label>
            <div class="d-flex align-items-center">
              <el-form-item prop="email" class="mb-0 w-100">
                <el-input :disabled="isVerifyCode" type="email" class="inner-input" ref="autoFocus" v-model="emailForm.email" autocomplete="on" placeholder="請輸入Email" />
              </el-form-item>
              <el-button class="ms-2" plain @click="verifyMemberEmail()" :disabled="isVerifyCode">
                <span>發送驗證碼</span>
              </el-button>
            </div>
          </div>
          <div class="d-flex flex-column mb-3">
            <label class="red_star">* <span>輸入驗證碼</span></label>
            <div class="d-flex align-items-center mt-2">
              <el-input type="text" v-model="verifyCodeInput" autocomplete="on" class="inner-input" @keydown.enter.prevent="login" :disabled="!isVerifyEmailSend" placeholder="請輸入驗證碼" />
              <el-button :disabled="!isVerifyEmailSend" class="ms-2" @click="verifyCode()">驗證<i class="fa-solid fa-badge-check ms-2" v-if="isVerifyCode"></i></el-button>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column">
          <div class="d-flex flex-column">
            <label class="red_star">* <span>設定密碼</span></label>
            <el-form-item prop="password">
              <el-input :disabled="!isVerifyCode" v-model="emailForm.password" type="password" autocomplete="new-password" show-password placeholder="請設定密碼" />
            </el-form-item>
          </div>
          <div class="d-flex flex-column">
            <label class="red_star">* <span>確認密碼</span></label>
            <el-form-item prop="passwordConfirm">
              <el-input :disabled="!isVerifyCode" v-model="emailForm.passwordConfirm" @keydown.enter.prevent="register" show-password autocomplete="off" placeholder="請再一次輸入密碼" />
            </el-form-item>
          </div>
          <ConfirmButton class="register_btn" @click="register()" text="綁定Email"></ConfirmButton>
        </div>
        <div><el-button type="primary" @click="bindEmail()">確認</el-button></div>
      </el-form>
    </div>
  </div>
</template>

<script>
import Storage from "../../../models/storage";
import { googleSdkLoaded } from "vue3-google-login";

export default {
  name: "EmailVerify",
  data() {
    const validatePassword = (rule, value, callback) => {
      if (!this.isVerifyCode) callback();
      if (value === "") {
        callback(new Error("密碼不可為空"));
      } else {
        if (this.emailForm.passwordConfirm !== "") {
          this.$refs.emailForm.validateField("passwordConfirm");
        }
        callback();
      }
    };
    const validatePasswordConfirm = (rule, value, callback) => {
      if (!this.isVerifyCode) callback();
      if (value === "") {
        callback(new Error("請再次輸入密碼"));
      } else if (value !== this.emailForm.password) {
        callback(new Error("密碼輸入錯誤"));
      } else {
        callback();
      }
    };
    return {
      verifyCodeInput: "",
      isVerifyEmailSend: false,
      isVerifyCode: false,
      ruleForm: {
        email: "",
        registerType: "",
      },
      emailForm: {
        email: "",
        password: "",
        passwordConfirm: "",
      },
      rules: {
        email: [
          {
            type: "email",
            message: "請輸入正確信箱格式",
            trigger: ["blur", "change"],
          },
          {
            validator: this.validateEmpty,
            trigger: "change",
          },
        ],
        password: [
          { validator: validatePassword, trigger: "change" },
          {
            pattern: /^(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%]).{8,}$/,
            message: "密碼條件：英文大寫、英文小寫、數字、符號 ! @ # $ %",
            trigger: "change",
          },
        ],
        passwordConfirm: [{ validator: validatePasswordConfirm, trigger: "change" }],
      },
    };
  },
  async mounted() {
    this.log_PageInfo("Login_EmailVerify", "12");
  },
  methods: {
    loginWithGoogle() {
      googleSdkLoaded(google => {
        google.accounts.oauth2
          .initCodeClient({
            client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
            scope: "email profile openid",
            redirect_uri: `${process.env.VUE_APP_CURRENT_DOMAIN}`,
            callback: response => {
              if (response.code) {
                this.googleAuthorize(response.code);
              }
            },
          })
          .requestCode();
      });
    },
    async googleAuthorize(code) {
      const vm = this;
      if (code == "" || code == null) {
        vm.notify("error", "綁定失敗！");
      }

      let user = vm.$store.state.auth.user;
      let userId = null;
      if (user != null) userId = user.id;

      let par = { AuthCode: code, UserId: userId };

      await vm.$store
        .dispatch("auth/bindGoogleLogin", par)
        .then((data) => {
          console.log(data);
          let user = Storage.get("user");
          user.email = data.email;
          user.isEmailVerified = true;
          Storage.set("user", user);

          vm.currentUser().email = data.email;
          vm.currentUser().isEmailVerified = true;
          vm.ruleForm.email = data.email;

          vm.verifyEmailClear();
          vm.notify("success", "綁定Email成功！");
          vm.$router.push("/ChargeMeContactBook/ClassRoomCenter");
        })
        .catch(() => {
          vm.notify("error", "綁定失敗！");
        });
    },
    bindEmail() {
      const vm = this;
      vm.$refs["emailForm"].validate(valid => {
        if (valid) {
          const data = {
            Email: vm.emailForm.email,
            Password: vm.emailForm.password,
          };
          vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/account/bindEmail`, data)
            .then(() => {
              let user = Storage.get("user");
              user.email = data.Email;
              user.isEmailVerified = true;
              Storage.set("user", user);

              vm.currentUser().email = data.Email;
              vm.currentUser().isEmailVerified = true;
              vm.ruleForm.email = data.Email;

              vm.verifyEmailClear();
              vm.notify("success", "綁定Email成功！");
              vm.$router.push("/ChargeMeContactBook/ClassRoomCenter");
            })
            .catch(err => {
              console.log(err);
              vm.notify("error", "綁定Email失敗！");
            });
        } else {
          vm.notify("warning", `請確認輸入資料是否正確!`);
        }
      });
    },
    verifyEmailClear() {
      const vm = this;
      vm.verifyCodeInput = "";
      vm.emailForm = {
        email: "",
        password: "",
        passwordConfirm: "",
      };
      vm.isVerifyEmailSend = false;
      vm.isVerifyCode = false;
    },
    verifyCode() {
      const vm = this;
      const data = {
        Email: vm.emailForm.email,
        VerifyCode: vm.verifyCodeInput.trim(),
      };

      if (data.VerifyCode == "") {
        vm.notify("error", `請輸入驗證碼`);
        return;
      }

      vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/account/VerifyCode`, data).then(res => {
        if (res.data.verifySuccess) {
          vm.isVerifyCode = true;
          vm.isVerifyEmailSend = false;
          vm.notify("success", "驗證成功！");
        } else {
          vm.notify("error", `驗證錯誤！`);
        }
      });
    },
    async checkUserByEmail() {
      const vm = this;
      try {
        const res = await vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/Account/CheckEmail/${vm.emailForm.email}`);
        if (res.data.registerType !== "") {
          vm.notify("error", `此信箱已被註冊`);
          return false;
        }
        return true;
      } catch (err) {
        return false;
      }
    },
    verifyMemberEmail() {
      const vm = this;
      vm.$refs["emailForm"].validate(async valid => {
        if (valid) {
          var isValid = await vm.checkUserByEmail(); //檢查是否有被註冊
          if (!isValid) return;

          const data = {
            Email: vm.emailForm.email,
          };
          vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/account/verificationByEmail`, data)
            .then(() => {
              vm.isVerifyEmailSend = true;
              vm.notify("success", "驗證信發送成功！");
            })
            .catch(err => {
              console.log(err);
              vm.notify("error", "驗證信發送失敗！");
            });
        } else {
          vm.notify("warning", `請確認輸入資料是否正確!`);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.member {
  text-align: start;
  padding: 0 1.5rem 1rem;
  :deep .el-form-item {
    border-bottom: 1px solid rgb(183, 183, 183);
    .el-form-item__content {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: flex-start;
      text-align: start !important;
      .el-input.disActive {
        .el-input__inner {
          cursor: default;
        }
        .el-input__wrapper {
          box-shadow: none;
        }
      }
    }
  }
}
label {
  text-align: start;
}
.red_star {
  color: red !important;
  text-align: start;
  span {
    font-size: 1.1rem;
    font-weight: 900;
    color: rgba(0, 0, 0, 0.55);
  }
}
.main {
  padding: 1rem;
  height: 100%;
  margin: auto;
  h3 {
    font-size: 1.5rem;
    margin: 1rem 0 2rem;
    font-weight: bold;
  }
  p{
    line-height: 1.5;
    font-size: 1.25rem;
    margin-bottom:3rem;
  }
}
.other_login {
  display: flex;
  // justify-content: space-around;
  justify-content: center;
  .icon {
    margin: 0.5rem 1.5rem;
  }
}
.icon {
  border-radius: 50%;
  box-shadow: 1px 1px 3px 1px #b0b0b0;
  overflow: hidden;
  width: 6rem;
  height: 6rem;
  transition: 0.2s;
  &:hover {
    cursor: pointer;
    box-shadow: 1px 1px 3px 1px #565656;
  }
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
#showOtherBindEmail {
  padding: 0 1rem;
}
@media screen and (min-width: 768px) {
  .main {
    width: 40vw;
    height: 100%;
    margin: auto;
  }
}
</style>
